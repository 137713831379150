import React from 'react'
import SmoothScroll from 'smoothscroll-for-websites'
import TransitionsHandler from './src/components/TransitionsHandler'

/**
 * Global styles
 */
import './src/assets/fonts/font-face.scss'
import './src/assets/styles/App.scss'

/** Add event listeners **/
if (window !== 'undefined') {
  /**
   * Add smooth scrolling
   */
  SmoothScroll({
    stepSize: 50,
    animationTime: 1200,
    fixedBackground: true,
  })
}

export const wrapPageElement = ({ element, props }) => (
  <TransitionsHandler {...props}>{element}</TransitionsHandler>
)

export const shouldUpdateScroll = () => false
