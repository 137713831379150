import React, { useState } from 'react'
import { AnimatePresence } from 'framer-motion'
import MenuButton from '@partials/MenuButton'
import Menu from './Menu'
import Link from '@partials/Link'
import logo from '../../assets/images/logo_white.png'
import '../../assets/styles/components/NavBar.scss'

const NavBar: React.FC = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false)

  return (
    <div id="nav-bar">
      <Link title="Home" className="logo" href="/">
        <img src={logo} alt="eiker-yejan-logo" />
      </Link>
      <AnimatePresence>{isMenuOpen && <Menu hideMenu={() => setIsMenuOpen(false)} />}</AnimatePresence>
      <MenuButton isOpen={isMenuOpen} onClick={() => setIsMenuOpen((prev) => !prev)} />
    </div>
  )
}

export default NavBar
