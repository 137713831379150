import React from 'react'
import '@sass/components/MenuButton.scss'

type Props = {
  isOpen: boolean
  onClick: () => void
}

const MenuButton: React.FC<Props> = ({ isOpen, onClick }) => {
  return (
    <button
      aria-label="menu-button"
      className={`${isOpen ? 'is--active' : 'is--hidden'}`}
      onClick={onClick}
      id="menu-button"
      title="Menu"
    >
      <span></span>
      <span></span>
      <span></span>
    </button>
  )
}

export default MenuButton
